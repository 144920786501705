/*******************************
         Site Overrides
*******************************/

@media only screen and (max-width: 767px){

.ui.modal > .actions {
    padding: 1rem 1rem 2.5rem !important;
}

}

.ui.basic.modal > .header {
    color: #424242;
}

.ui.page.modals.dimmer.transition.visible.active {
    background-color: #ffffff;
}


.ui.basic.modal > .header, .ui.basic.modal > .content, .ui.basic.modal > .actions {
    background-color: #ffffff;
}


.ui.basic.modal {
    background-color: #ffffff00;
    border: none;
    border-radius: 0em;
    box-shadow: none !important;
    color: #424242;
}


.ui.modal > .close {
    cursor: pointer;
    position: absolute;
    top: -2.5rem;
    right: -2.5rem;
    z-index: 1;
    opacity: 0.8;
    font-size: 1.25em;
    color: #777777;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.625rem 0rem 0rem 0rem;
}
