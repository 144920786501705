/*******************************
         Site Overrides
*******************************/



.ui.basic.label {
  
    color: #484848de;
  
}

