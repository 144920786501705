/*******************************
         Site Overrides
*******************************/

a:hover {
    color: #000000;
}

p:first-child {
    margin-top: 1em;
}

body,
  h1,
  h2 {
    margin: 0;
    padding: 0;
  }

h2 {
    font-size: 1.4rem;
}

em {
  background: none;
  font-style: italic;

}

.product-details h4 {
    margin-bottom: 0.5rem;
    margin-top: 0.8rem;
    color: #767474;
}

.product-details h3 {
    
    margin-top: 1rem;
    margin-bottom: 2rem;
   
}

.product-details .ui.basic.active.button,.ui.basic.button {
  margin-bottom:0.5rem;

}

.product-details h5 {
    margin-bottom: 0.5rem;
    margin-top: 0.8rem;
  
}

.ship-label ui.small.basic.label{
  margin-left:0;
}

.ui.dimmer {
  background-color:#e8e8e8cc;
}


.ui.page.modals.dimmer.transition.visible.active {

  background-color:#e8e8e8cc;

}

.ui.scrolling.modal.transition.visible.active, .ui.modal.transition.visible.active {
  
  box-shadow: 1px 3px 3px 0px rgba(255, 255, 255, 0.2), 1px 3px 15px 2px rgba(177, 177, 177, 0.2);


}

